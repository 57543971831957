import React from "react";
import { ReactComponent as MessageSVG } from "./assets/message.svg";
import css from "./storeClosing.module.css";

const StoreClosing = () => {
  return (
    <React.Fragment>
      <div className={css.messageContainer}>
        <MessageSVG className={css.messageSVG} />
        <span style={{ marginLeft: "10px" }}>
          Permanently closing September 30th, 2023
        </span>
      </div>
    </React.Fragment>
  );
};

export default StoreClosing;
